import '../../styles/index.scss'
import '../../styles/pages/about.scss'
import Page from './page.js'
import Util from '../util/util.js';
import gsap from 'gsap';
import * as prismic from 'https://cdn.skypack.dev/@prismicio/client'

export default class About extends Page {
  constructor(options) {
    super(options);
  }
  onDataReady() {
    super.onDataReady();
    const header = document.querySelector('.header');
    const $headerWordsContainer = document.querySelector('.header-words');
    const headerWords = this.doc.data.hero_words;
    headerWords.forEach(item => {
      const $row = document.createElement('div');
      const $content = document.createElement('div');
      const $hr = document.createElement('hr');

      $row.classList.add('header-row');
      $content.classList.add('header-row-content');

      $row.appendChild($content);

      const $word = document.createElement('h1');
      $word.classList.add('h1', 'header-word');
      $word.innerHTML = `${item.word.split('').map(l => `<span class="letter ${l === '' ? 'space' : ''}">${l}</span>`).join('')}`
      $content.appendChild($word);
      $row.appendChild($hr);

      $headerWordsContainer.appendChild($row);
    });
    const $headerImage = document.querySelector('.header-image');
    $headerImage.setAttribute('src', this.doc.data.hero_image.url);
    
    document.querySelector('#bio-image').setAttribute('src', this.doc.data.about_image.url);
    document.querySelector('#bio-content').innerHTML = prismic.asHTML(this.doc.data.about_text);

    document.querySelector('#services .section-title').innerHTML = this.doc.data.services_title;

    const $servicesList = document.querySelector('#services-list');
    this.doc.data.services.forEach(item => {
      const $service = document.createElement('div');
      $service.classList.add('service');
      const $serviceTitle = document.createElement('h2');
      $serviceTitle.classList.add('h2', 'service-title');
      $serviceTitle.innerHTML = item.item_title;
      const $serviceContent = document.createElement('div');
      $serviceContent.classList.add('service-content');
      $serviceContent.innerHTML = prismic.asHTML(item.item_list);
      $service.appendChild($serviceTitle);
      $service.appendChild($serviceContent);
      $servicesList.appendChild($service);
      $serviceContent.querySelectorAll('p').forEach(p => {
        p.setAttribute('data-scroll-item', '');
      });
    });

    document.querySelector('#clients .section-title').innerHTML = this.doc.data.clients_title;
    const $clientsList = document.querySelector('#clients-list');
    this.doc.data.clients.forEach(item => {
      const $client = document.createElement('div');
      const $img = document.createElement('img');

      $client.classList.add('client');
      $img.setAttribute('src', item.item_image.url);
      $client.appendChild($img);
      $clientsList.appendChild($client);
    });
    const $you = document.createElement('a');
    $you.classList.add('client', 'you');
    $you.setAttribute('href', '/contact.html');
    $you.innerHTML = `
      <div class="you-content">
        <div class="bg"></div>
        <p class="smart-text">${this.doc.data.clients_you}</p>
        <p class="smart-text">${this.doc.data.clients_you}</p>
      </div>
    `;
    $clientsList.appendChild($you);
    const $youBG = $you.querySelector('.bg');
    const youData = {
      x: {c: 0, t: 0},
      y: {c: 0, t: 0},
      z: {c: 0, t: 0},
    }
    const youRaf = () => {
      youData.x.c += (youData.x.t - youData.x.c) * 0.1;
      youData.y.c += (youData.y.t - youData.y.c) * 0.1;
      youData.z.c += (youData.z.t - youData.z.c) * 0.1;
      $youBG.style.transform = `perspective(1000px) rotateX(${youData.x.c}deg) rotateY(${youData.y.c}deg) translateZ(${youData.z.c}px)`;
      requestAnimationFrame(youRaf);
    }
    $you.addEventListener('mousemove', (e) => {
      const rect = $you.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      const w = rect.width;
      const h = rect.height;
      const offsetX = 0.5 - x / w;
      const offsetY = 0.5 - y / h;

      const rotateX = offsetY * 20;
      const rotateY = offsetX * 20;
      const translateZ = 50;

      youData.x.t = rotateX;
      youData.y.t = rotateY;
      youData.z.t = translateZ;

    });
    $you.addEventListener('mouseleave', () => {
      youData.x.t = 0;
      youData.y.t = 0;
      youData.z.t = 0;
    });
    youRaf();

    document.querySelector('#contact .section-title').innerHTML = this.doc.data.contact_title;
    const $contactContent = document.querySelector('#contact-content');
    $contactContent.innerHTML = prismic.asHTML(this.doc.data.contact_description);

    document.querySelector('#awards .section-title').innerHTML = this.doc.data.awards_title;
    const $awardsList = document.querySelector('#awards-list');
    const awards = this.doc.data.awards;
    awards.forEach(item => {
      const $award = document.createElement('a');
      $award.classList.add('award');
      $award.setAttribute('href', item.item_link.url || '#');
      $award.innerHTML = `
        <span class="column award-title">${item.item_title}</span>
        <span class="column award-platform">${item.item_platform}</span>
        <span class="column award-description">${item.item_description}</span>
      `;
      $awardsList.appendChild($award);
      $award.setAttribute('data-scroll-item', '');
    });
  }
  paralax(data) {
    super.paralax(data);
  }
  initIntro() {
    super.initIntro();
  }
  intro() {
    super.intro();
  }
}
new About({page: 'about'});